@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&display=swap');

:root {
  --font-instrument: 'Instrument Serif', serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global cursor styles */
button,
a,
[role="button"],
input[type="submit"],
input[type="button"],
input[type="reset"],
.cursor-pointer {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animate-scroll,
.animate-scroll-reverse {
  perspective: 2000px;
  transform-style: preserve-3d;
  width: max-content;
  position: relative;
}

.animate-scroll {
  animation: scroll 40s linear infinite;
}

.animate-scroll-reverse {
  animation: scroll-reverse 40s linear infinite;
}

/* Hide scrollbars but keep functionality */
.animate-scroll,
.animate-scroll-reverse {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.animate-scroll::-webkit-scrollbar,
.animate-scroll-reverse::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 2));
  }
}

@keyframes scroll-reverse {
  0% {
    transform: translateX(calc(-100% / 2));
  }
  100% {
    transform: translateX(0);
  }
}

/* Tooltip animations */
@keyframes tooltipIn {
  from {
    opacity: 0;
    transform: translateY(8px) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes tooltipOut {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(-4px) scale(0.98);
  }
}

.TooltipContent {
  background-color: #1a1a1a;
  color: white;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  user-select: none;
  animation: tooltipIn 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

.TooltipContent[data-state='closed'] {
  animation: tooltipOut 0.15s ease-in-out;
}

/* Signature animation */
.signature-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: sign 2s ease forwards;
}

@keyframes sign {
  to {
    stroke-dashoffset: 0;
  }
}
